<template>
    <link rel="stylesheet" href="https://unpkg.com/leaflet@1.9.4/dist/leaflet.css" integrity="sha256-p4NxAoJBhIIN+hmNHrzRCf9tD/miZyoHS5obTRR9BMY=" crossorigin="" />
    <div class="absolute w-full h-full">
        <span class="block absolute bottom-2 left-2 z-30">{{ counter }}</span>
        <div v-if="showOptions" class="absolute top-5 right-5 bg-white z-50 rounded-lg">
            <div @click="showOptions = !showOptions" class="absolute top-0 right-0 font-bold text-xs px-2 py-1 cursor-pointer">Einklappen</div>
            <div>
                <span class="text-xs font-bold block mx-2 mt-2">Name</span>
                <input v-model="ownName" placeholder="Dein Name" type="text" class="border-b border-black mx-2" @input="nameChanged = true" />
                <button v-if="nameChanged" @click="changeName" class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">Speichern</button>
            </div>
            <div class="mb-3">
                <span class="text-xs font-bold block mx-2 mt-2">Gruppenname</span>
                <input v-model="groupName" placeholder="Gruppencode" type="text" class="border-b border-black mx-2" @input="groupChanged = true" />
                <button v-if="groupChanged" @click="changeGroup" class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">Speichern</button>
            </div>
        </div>
        <div v-else>
            <button @click="showOptions = !showOptions" class="absolute top-5 right-5 z-50 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">Optionen</button>
        </div>
    </div>
    <div class="absolute bottom-5 right-5 bg-white z-50">
        <button @click="centerMap()" class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">Fokus</button>
    </div>
    <div class="w-full h-full absolute z-0" id="map"></div>
</template>

<script>
import L from "leaflet";
export default {
    data() {
        return {
            ownName: "",
            groupName: "",
            locations: {},
            socket: null,
            currentPosition: null,
            map: null,
            nameChanged: false,
            groupChanged: false,
            showOptions: false,
            counter: 0,
            id: 0,
            interval: null
        }
    },
    mounted() {
        // Create a map instance
        // find my location with geolocation
        this.map = L.map("map").setView([50.937433, 6.960287], 15);
        L.tileLayer("https://tile.openstreetmap.org/{z}/{x}/{y}.png", {
            attribution: "Map data &copy; <a href='https://www.openstreetmap.org/'>OpenStreetMap</a> contributors",
            maxZoom: 18,
        }).addTo(this.map);
        let vm = this;
        this.getPositionAndUpdate(true)
        navigator.geolocation.watchPosition((position) => {
            //vm.updatePosition(position)
        })
    },
    methods: {
        centerMap() {
            if (this.locations[this.id] != null) this.map.panTo(this.locations[this.id].marker.getLatLng())
        },

        socketReady() {
            return this.socket && this.socket.readyState == WebSocket.OPEN
        },
        changeName() {
            //store name in local storage
            localStorage.setItem("name", this.ownName);
            if (this.locations[this.id]) {
                this.locations[this.id].marker.bindTooltip(this.ownName, { permanent: true }).openTooltip();
            }
            if (this.socketReady()) {
                this.getPositionAndUpdate()
            }
            this.nameChanged = false;
            this.openWebsocketConnection()
        },
        getPositionAndUpdate(initial = false) {
            console.log("getting position...")
            navigator.geolocation.getCurrentPosition((position) => {
                console.log("got it")
                if (initial) {
                    this.map.panTo(new L.LatLng(position.coords.latitude, position.coords.longitude));
                }
                this.currentPosition = position.coords;
                if (this.socketReady()) {
                    try {
                        this.socket.send(JSON.stringify({ id: this.id, type: "position", name: this.ownName, lat: position.coords.latitude, lng: position.coords.longitude }))
                    }
                    catch (e) {
                        this.openWebsocketConnection(true)
                    }
                }
            })
        },
        changeGroup() {
            let vm = this
            localStorage.setItem("group", this.groupName);
            Object.keys(this.locations).forEach(key => {
                vm.map.removeLayer(vm.locations[key].marker)
            })
            this.locations = {}
            this.openWebsocketConnection(true)
            this.groupChanged = false
        },

        openWebsocketConnection(reopen = false) {
            if (this.socketReady() && reopen) {
                this.socket.close()
                this.socket = null;
            }
            if (this.socket != null || this.groupName == "" || this.ownName == "") return;
            this.announceIdAndGroup()
            this.socket = new WebSocket(process.env.VUE_APP_WEBSOCKET_URL + this.groupName + "/" + this.id);
            let vm = this
            this.socket.onopen = function () {
                vm.getPositionAndUpdate()
            }
            this.socket.onmessage = function (event) {
                update(event.data);
            };
            if (this.interval) {
                clearInterval(this.interval)
            }
            this.interval=setInterval(() => {
                console.log("testing...")
                if (vm.socketReady()) {
                    console.log("socket redy")
                    try {
                        vm.socket.send(JSON.stringify({ type: "ping" }));
                        console.log("sent ping")
                    } catch (e) {
                        vm.openWebsocketConnection(true);
                        console.log("ping failed, reopening websocket")
                    }
                }
                else{
                    this.socket=null
                    vm.openWebsocketConnection();
                    console.log("socket not ready")
                }
            }, 10000)
            function update(message) {
                let newLocations = JSON.parse(message);
                newLocations.forEach(location => {
                    if (vm.locations[location.id]) {
                        vm.locations[location.id].marker.setLatLng(L.latLng(location.lat, location.lng))
                        vm.locations[location.id].name = location.name
                        vm.locations[location.id].updatedAt = location.updatedAt
                    } else {
                        vm.locations[location.id] = { marker: L.marker([location.lat, location.lng]), name: location.name, updatedAt: location.updatedAt }
                        vm.locations[location.id].marker.addTo(vm.map);
                        vm.locations[location.id].marker.on('click', function (event) {
                            alert(vm.locations[location.id].name)
                        });
                    }
                    vm.locations[location.id].marker.bindTooltip(location.name + "<br>" + (new Date(location.updatedAt)).toLocaleTimeString(), { permanent: true }).openTooltip();
                });
                // Function logic for updating based on the received message
            }
        },
        generateID(length) {
            const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
            const idArray = [];
            for (let i = 0; i < length; i++) {
                const randomIndex = Math.floor(Math.random() * characters.length);
                idArray.push(characters[randomIndex]);
            }
            return idArray.join('');
        },
        announceIdAndGroup() {
            var req = new XMLHttpRequest()
            req.open('GET', process.env.VUE_APP_API_URL + "announce/" + this.id + "/" + this.groupName)
            req.send()
        }
    },
    created() {
        this.ownName = localStorage.getItem("name");
        this.groupName = localStorage.getItem("group");
        this.id = localStorage.getItem("id");
        if (!this.ownName) this.ownName = "";
        if (!this.groupName) this.groupName = "";
        if (!this.groupName || !this.ownName) this.showOptions = true
        if (!this.id) {
            this.id = this.generateID(20)
            localStorage.setItem("id", this.id);
        }
        this.openWebsocketConnection()
    }

};
</script>

<style>
html,
body {
    padding: unset;
    margin: unset;
}

#map {
    width: 100%;
    height: 100vh;
}
</style>

